import React, { useState } from 'react';
import { Card, Form, Button, InputGroup  } from 'react-bootstrap';
import InputMask from 'react-input-mask';

import { SecuredContext } from 'core/auth/secured';
import { SendConfirmation } from './send-confirmation';
import { getRawMoney } from 'components/utils';
import { MyIntlTelInput } from 'components/my-intl-tel-input';

const Send = () => {
  const { userDetails } = React.useContext(SecuredContext);
  const [amount, setAmount] = useState('');
  const [isValidAmount, setIsValidAmount] = useState(false);
  const [phone, setPhone] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [label, setLabel] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [method, setMethod] = useState('');
  const [action, setAction] = useState('');


  const isReady = userDetails.activeCard && isValidAmount && isValidPhone && action !=="" && method !=="";

  const updateAmount = (maskedAmount) => {
    const amount = getRawMoney(maskedAmount);
    setAmount(amount);
    setIsValidAmount(amount >= 0);
  };

  const updatePhone = (isValid, value, selectedCountryData, fullNumber, extension) => {
    setPhone(fullNumber);
    setIsValidPhone(!!fullNumber);
  };

  const handleSendRequest = (event) => {
    event.preventDefault();
    setIsConfirming(true);
  };

  const cleanup = () => {
    setAmount('');
    setPhone('');
    setLabel('');
    setIsConfirming(false);
    setMethod('');
    setAction('');
  };

  const cancel = () => setIsConfirming(false);

  return (
    <div id="send-money" className="d-flex flex-column w-100">
      <Card className="rounded-gp border-0 bg-light p-md-3">
        <Card.Header className="bg-transparent border-0 d-none d-md-block">
          <span className="display-5">Transfers</span>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSendRequest} className="text-center">
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <InputGroup>
                <InputGroup.Text className="border-end-0 input-group-text fw-bold text-reset border-end-0">
                    XOF
                  </InputGroup.Text>
                  <Form.Control
                    className="jumbo-input form-control border-start-0 fw-bold"
                    as={InputMask}
                    mask="9.999.999,99"
                    maskChar={''}
                    placeholder="0.000.000,00"
                    onChange={(e) => updateAmount(e.target.value)}
                  />
                </InputGroup>
                <InputGroup className="my-3">
                  <MyIntlTelInput onPhoneChangeCallBack={updatePhone}/>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="border-end-0 input-group-text fw-bold text-reset border-end-0">
                    Method
                  </InputGroup.Text>
                  <Form.Control as="select" aria-label="Default select example" onChange={(e) => setMethod(e.target.value)}>
                    <option value="">Select method</option>
                    <option value="top_up">Top up</option>
                    <option value="bank_transfer">Bank transfer</option>
                  </Form.Control>
                </InputGroup>
                 <InputGroup className="mb-3">
                  <InputGroup.Text className="border-end-0 input-group-text fw-bold text-reset border-end-0">
                    Action
                  </InputGroup.Text>
                  <Form.Control as="select" aria-label="Default select example" onChange={(e) => setAction(e.target.value)}>
                    <option value="">Select action</option>
                    <option value="send">Send</option>
                    <option value="request">Request</option>
                  </Form.Control>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="border-end-0 fw-bold">For</InputGroup.Text>
                  <Form.Control
                    className="border-start-0"
                    onChange={(e) => setLabel(e.target.value)}
                    type="text"
                    placeholder="Rent, Diner..."
                  />
                </InputGroup>
              </div>
            </div>
            <Button type="submit" className="px-5 mt-3 rounded-pill text-white" disabled={!isReady}>
              <span>Continue</span>
            </Button>
          </Form>
        </Card.Body>
      </Card>
      {isConfirming && (
        <SendConfirmation amount={amount} phone={phone} label={label} onConfirm={cleanup} onCancel={cancel} action={action} method={method} />
      )}
    </div>
  );
};

export { Send };
