import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const MyIntlTelInput = ({ onPhoneChangeCallBack, value }) => {

  return (
    <IntlTelInput
    preferredCountries={[]}
    defaultCountry="ml"
    defaultValue={value}
    numberType="MOBILE"
    onlyCountries={['ml', 'us']}
    onPhoneNumberChange={onPhoneChangeCallBack}
    inputClassName="form-control"
    variant="outline-secondary"

    useMobileFullscreenDropdown={false}
    format={true}
    telInputProps={{ maxLength: 15 }}
  />
  );
};

export { MyIntlTelInput };
