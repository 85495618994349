import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Navbar } from 'react-bootstrap';
import { FiActivity, FiHelpCircle, FiSettings, FiLogOut } from 'react-icons/fi';
import { AiOutlineMenu } from 'react-icons/ai';
import { RiDashboardLine, RiDashboardHorizontalLine } from "react-icons/ri";
import { useLocation, useNavigate, NavLink, Navigate } from 'react-router-dom';

import { SecuredContext } from 'core/auth/secured';
import { ReactComponent as GopayIcon } from 'assets/gopay-icon.svg';
import { ActiveUser } from 'components/active-user';
import { MoneyIcon } from 'components/money-icon';
import { logOut } from 'core/auth/auth-utils';

const Main = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails } = React.useContext(SecuredContext);
    const isNewUser = !(userDetails.firstName && userDetails.lastName);
  const openHelp = () => {};
  const doLogOut = () => {
    return logOut()
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="main d-flex flex-column flex-grow-1">
      <Navbar className="border-bottom" expand="md">
        <div className="container-xl">
          <Navbar.Brand>
            <GopayIcon height="42" width="42" />
          </Navbar.Brand>
          <Navbar.Toggle className="border-0 px-0">
            <AiOutlineMenu className="text-primary h2 m-0" />
          </Navbar.Toggle>
          <Navbar.Collapse>
            <Nav className="d-md-none text-end pt-2">
              <Nav.Link as={NavLink} to="/help" className="text-dark">
                Help
                <FiHelpCircle className="ms-2" />
              </Nav.Link>
              <Nav.Link onClick={doLogOut} className="text-dark">
                Logout
                <FiLogOut className="ms-2" />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Nav className="d-none d-md-flex">
            <Nav.Link onClick={openHelp} className="d-flex flex-row align-items-center">
              <div className="d-flex flex-row align-items-center">
                <FiHelpCircle className="me-2" />
                <span>Help</span>
              </div>
            </Nav.Link>
            <Nav.Item className="ms-3">
              <ActiveUser onLogOut={doLogOut} />
            </Nav.Item>
          </Nav>
        </div>
      </Navbar>
      <div className="container-xl d-flex flex-grow-1 mb-md-3">
        <div className="row pt-md-4 flex-nowrap flex-grow-1 align-items-stretch flex-column-reverse flex-md-row">
          <div className="col-md-2 d-flex align-items-start justify-content-center border-top border-top-md-0 justify-content-md-start">
            <Nav
              className="h5 gap-4 d-flex flex-row flex-grow-1 mx-4 align-items-center justify-content-center justify-content-md-start"
              activeKey={location.pathname}>
              <Nav.Link as={NavLink} to="/dashboard" disabled={isNewUser}>
                <div className="d-flex flex-row align-items-center">
                  <RiDashboardLine className="gp-nav-icon" />
                  <span className="d-none d-md-inline ms-2">Dashboard</span>
                </div>
              </Nav.Link>
              <Nav.Link as={NavLink} to="/send" disabled={isNewUser}>
                <div className="d-flex flex-row align-items-center">
                  <MoneyIcon className="gp-nav-icon" />
                  <span className="d-none d-md-inline ms-2">Send</span>
                </div>
              </Nav.Link>
              <Nav.Link as={NavLink} to="/user-details">
                <div className="d-flex flex-row align-items-center my-2">
                  <FiSettings className="gp-nav-icon" />
                  <span className="d-none d-md-inline ms-2">Settings</span>
                </div>
              </Nav.Link>
            </Nav>
          </div>
          <div className="d-flex flex-column flex-grow-1 flex-shrink-1 overflow-auto col-md-10 border-start-md pt-4 mb-3 my-lg-0 ps-lg-5">
            {children}
          </div>
        </div>
      </div>
      {/* <div className="w-100 p-2 text-end text-muted small">GoPay 2021 - All Rights Reserved</div> */}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export { Main };
