import React, { useState } from 'react';
import { Card, Form, Button, InputGroup } from 'react-bootstrap';
import { FiActivity } from 'react-icons/fi';
import { RiHotspotLine, RiFundsBoxLine } from "react-icons/ri";
import { TbDeviceSpeaker } from "react-icons/tb";

import { SecuredContext } from 'core/auth/secured';
import { apiClient } from 'core/api/api-client';
import { useEffect } from 'react';

const Dashboard = () => {
    return (
        <>
            <Card>
                <Card.Title>
                    <RiHotspotLine className="gp-nav-icon" />
                    <span >Hotspot</span>
                </Card.Title>
                <Card.Body>
                    Connect to lightning fast wifi.
                </Card.Body>
                <Card.Footer>
                    <Card.Link href="/hotspots">Connect </Card.Link>
                </Card.Footer>
            </Card>
            <br />
            <Card>
                <Card.Title>
                    <TbDeviceSpeaker className="gp-nav-icon" />
                    <span >Internet Of Things</span>
                </Card.Title>
                <Card.Body>
                    Trace your tractors, cars, etc..
                </Card.Body>
                <Card.Footer>
                    <Card.Link href="/devices">Track</Card.Link>
                </Card.Footer>
            </Card>
            <br />
            <Card>
                <Card.Title>
                    <RiFundsBoxLine className="gp-nav-icon" />
                    <span >Raise fund</span>
                </Card.Title>
                <Card.Body>
                    Trace your tractors, cars, etc..
                </Card.Body>
                <Card.Footer>
                    <Card.Link href="/raise-fund">Raise fund</Card.Link>
                </Card.Footer>
            </Card>
        </>
    );
};

export { Dashboard };
