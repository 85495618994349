import React, { useState } from 'react';
import { Card, Form, Button, InputGroup  } from 'react-bootstrap';
import InputMask from 'react-input-mask';

import { SecuredContext } from 'core/auth/secured';
import { RaiseFundConfirmation } from './raise-fund-confirmation';
import { getRawMoney } from 'components/utils';
import { MyIntlTelInput } from 'components/my-intl-tel-input';
import { apiClient } from 'core/api/api-client';

const RaiseFund = () => {
  const { userDetails } = React.useContext(SecuredContext);
  const [amount, setAmount] = useState('');
  const [isValidAmount, setIsValidAmount] = useState(false);
  const [phone, setPhone] = useState('');
  const [summary, setSummary] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [fundDetails, setFundDetails] = useState('');
  const [action, setAction] = useState('');


  const isReady =  isValidAmount && summary !=="" && fundDetails !=="";

  const updateAmount = (maskedAmount) => {
    const amount = getRawMoney(maskedAmount);
    setAmount(amount);
    setIsValidAmount(amount >= 0);
  };

  const handleRaiseRequest = (event) => {
    event.preventDefault();
    setIsConfirming(true);
  };

  const cleanup = () => {
    setAmount('');
    setSummary('');
    setIsConfirming(false);
    setFundDetails('');
  };

  const cancel = () => setIsConfirming(false);

  return (
    <div id="send-money" className="d-flex flex-column w-100">
      <Card className="rounded-gp border-0 bg-light p-md-3">
        <Card.Header className="bg-transparent border-0 d-none d-md-block">
          <span className="display-5">Request funds</span>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleRaiseRequest} className="text-center">
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <InputGroup className="mb-3">
                  <InputGroup.Text className="input-group-text fw-bold border-end-0"> Requesting (XOF) </InputGroup.Text>
                  <Form.Control
                    className="jumbo-input form-control border-start-0 fw-bold"
                    as={InputMask}
                    mask="9.999.999,99"
                    maskChar={''}
                    placeholder="0.000.000,00"
                    onChange={(e) => updateAmount(e.target.value)}
                  />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text className="border-end-0 fw-bold">Summary</InputGroup.Text>
                  <Form.Control
                    className="border-start-0"
                    onChange={(e) => setSummary(e.target.value)}
                    type="text"
                    placeholder="Quick summary..."
                  />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text className="border-end-0 fw-bold">Details</InputGroup.Text>
                  <Form.Control
                    className="border-start-0"
                    onChange={(e) => setFundDetails(e.target.value)}
                    type="text"
                    placeholder="Details about the request..."
                  />
                </InputGroup>
              </div>
            </div>
            <Button type="submit" className="px-5 mt-3 rounded-pill text-white" disabled={!isReady}>
              <span>Continue</span>
            </Button>
          </Form>
        </Card.Body>
      </Card>
      {isConfirming && (
        <RaiseFundConfirmation amount={amount} summary={summary} fundDetails={fundDetails} onConfirm={cleanup} onCancel={cancel} />
      )}
    </div>
  );
};

export { RaiseFund };
