export const formatMoney = (amount) => {
  return amount;
};

const sanitizeNumber = (formattedNumber) =>
  formattedNumber.replace(',', '').replace('.', '').replace('(', '').replace(')', '');

export const getRawMoney = sanitizeNumber;

export const formatPhone = (phone) => {
  return phone;
};

export const getRawPhone = sanitizeNumber;

export const formatNumber = () => {};

export const formatDate = () => {};
