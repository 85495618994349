import React, { useState } from 'react';
import { Card, Form, Button, InputGroup } from 'react-bootstrap';

import { SecuredContext } from 'core/auth/secured';
import { apiClient } from 'core/api/api-client';
import { useEffect } from 'react';
import { FcRightDown2 } from "react-icons/fc";


const Hotspots = () => {
  const { userDetails } = React.useContext(SecuredContext);
  const [isLoading, setIsLoading] = useState(false);
  const [activeProfiles, setActiveProfiles] = useState(false);

  const fetchUserActiveProfiles = (username) => {
    setIsLoading(true);
    apiClient
      .fetchUserActiveProfiles(username)
      .then((response) => setActiveProfiles(response))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchUserActiveProfiles(userDetails.userUID);
  }, []);

  return (
    <div id="hotspots">
      {
        activeProfiles.length > 0 ? (
          <div> You're connected, continue... </div>
        ) : (
          <>
            <Card>
              <Card.Title>
                <FcRightDown2 className="gp-nav-icon" />
                <span >Buy a day</span>
              </Card.Title>
              <Card.Body>
                Unlimited connection for a 24H 
              </Card.Body>
              <Card.Footer>
                500 FCFA <br/>
                <Card.Link href="/#">Buy </Card.Link>
              </Card.Footer>
            </Card>

            <br />

            <Card>
              <Card.Title>
                <FcRightDown2 className="gp-nav-icon" />
                <span >By a week</span>
              </Card.Title>
              <Card.Body>
                Unlimited connection for a week. 
              </Card.Body>
              <Card.Footer>
                3 000 FCFA <br/>
                <Card.Link href="/#">Buy </Card.Link>
              </Card.Footer>
            </Card>

            <br />

            <Card>
              <Card.Title>
                <FcRightDown2 className="gp-nav-icon" />
                <span >By a Month</span>
              </Card.Title>
              <Card.Body>
                Unlimited connection for a month. 
              </Card.Body>
              <Card.Footer>
                10 000 FCFA <br/>
                <Card.Link href="/#">Buy a day</Card.Link>
              </Card.Footer>
            </Card>
          </>
        )
      }
    </div>
  );
};

export { Hotspots };
