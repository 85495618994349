import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { VscCheck, VscError } from 'react-icons/vsc';

import { formatMoney, formatPhone } from 'components/utils';
import { apiClient } from 'core/api/api-client';
import { SecuredContext } from 'core/auth/secured';

const RaiseFundConfirmation = ({ amount, summary, fundDetails, onConfirm, onCancel}) => {
  const { userDetails } = React.useContext(SecuredContext);
  const [confirmationState, setConfirmationState] = useState('confirming');
  const [errorCode, setErrorCode] = useState();

  const sendMoney = () => {
    setConfirmationState('sending');
    apiClient
      .raiseFund({
        amount,
        summary,
        fundDetails: fundDetails
      })
      .then(() => {
        setConfirmationState('success');
      })
      .catch((error) => {
        //FIXME: add error code to the API error response
        setErrorCode(error);
        setConfirmationState('failure');
        console.log(error);
      });
  };

  let title;
  let overlayContent;
  let footer;
  switch (confirmationState) {
    case 'confirming':
      title = 'Fund raising details';
      footer = (
        <div className="d-flex flex-row flex-grow-1 justify-content-between">
          <Button variant="link" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button variant="primary" className="rounded-pill text-white px-4" onClick={sendMoney}>
            Send
          </Button>
        </div>
      );
      break;
    case 'sending':
      title = 'Sending...';
      overlayContent = (
        <>
          <Spinner animation="border" role="status" className="text-primary gp-icon flex-shrink-0">
            <span className="visually-hidden">Sending...</span>
          </Spinner>
          <div className="mt-2 text-center">Please wait</div>
        </>
      );
      footer = (
        <div className="invisible">
          <Button></Button>
        </div>
      );
      break;
    case 'success':
      title = 'Success!';
      overlayContent = (
        <>
          <VscCheck className="text-success gp-icon flex-shrink-0" />
          <div className="mt-2 text-center">Your money is on the way!</div>
        </>
      );
      footer = (
        <div className="d-flex flex-row flex-grow-1 justify-content-center">
          <Button variant="primary" className="rounded-pill text-white px-5" onClick={() => onConfirm()}>
            Close
          </Button>
        </div>
      );
      break;
    case 'failure':
      title = 'Oops!';
      overlayContent = (
        <>
          <VscError className="text-danger gp-icon flex-shrink-0" />
          <div className="text-center">
            <p className="my-2">The money could not be sent,</p>
            <p>
              click <Link to={`/help#errorCodes${errorCode ? `-${errorCode}` : ''}`}>here</Link> for help
            </p>
          </div>
        </>
      );
      footer = (
        <div className="d-flex flex-row flex-grow-1 justify-content-center">
          <Button variant="primary" className="rounded-pill text-white px-5" onClick={() => onCancel()}>
            Close
          </Button>
        </div>
      );
      break;
    default:
  }
  const overlay = <div className="d-flex flex-column align-items-center">{overlayContent}</div>;

  return (
    <Modal id="send-confirmation-modal" show={true} centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="position-relative">
          {confirmationState !== 'confirming' && (
            <div className="position-absolute w-100 h-100 bg-white d-flex flex-column align-items-center justify-content-center">
              {overlay}
            </div>
          )}
          <div>
            <p>
              <span className="fw-bold me-2">Summary</span> {summary}
            </p>
            <p>
              <span className="fw-bold me-2">Amount</span>CFA {formatMoney(amount)}
            </p>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};

export { RaiseFundConfirmation };
