import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { FiLogOut, FiUser } from 'react-icons/fi';
import { Link } from "react-router-dom"
import { ReactComponent as GopayIcon } from 'assets/gopay-icon.svg';


import { SecuredContext } from 'core/auth/secured';

const LandingPage = () => {
   return (
      <div id="landing-page" className="h-100 w-100 bg-gp-gradient">
            <Navbar className="border-bottom" expand="md">
              <div className="container-xl">
                <Navbar.Brand>
                  <GopayIcon height="42" width="42" />
                </Navbar.Brand>
               </div>
            </Navbar>
             <div className="container d-flex flex-column flex-grow-1 justify-content-center">
                    <h1 className="text-light text-center mb-4 pb-2 display-6">Welcome to WaatiX</h1>
                    <h3 className="text-light text-center mb-4 pb-2 display-6">Next gen tech</h3>
                    <Link to="/login" className="text-light text-center mb-4 pb-2 display-6">Start Now!</Link>
             </div>
      </div>
     );
};

export { LandingPage };
